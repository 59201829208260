import { UserRole } from '@/core';
import i18n from '@/plugins/i18n';
import { OrganizationType, UserModel, UserRule } from '@/core/enums';
import { UserInfo } from '@/store';

export function getMenuItems(user: UserInfo): MenuItem[] {
  const localization = localStorage.getItem('localization');
  if (localization) {
    i18n.locale = localization;
  }

  const USER: MenuItem = {
    icon: 'users',
    text: i18n.t('general.menuItems.users').toString(),
    link: '/users',
  };

  const ORGANIZATION: MenuItem = {
    icon: 'organization',
    text: i18n.t('general.menuItems.organizations').toString(),
    link: '/organizations',
  };

  const VEHICLE_DICTIONARY: MenuItem = {
    icon: 'vehicleDictionary',
    text: i18n.t('general.menuItems.vehiclesDictionaries').toString(),
    link: '/vehicles-dictionaries',
  };

  const VEHICLE: MenuItem = {
    icon: 'vehicles',
    text: i18n.t('general.menuItems.vehicles').toString(),
    link: '/vehicles',
  };

  const VEHICLE_MONITORING: MenuItem = {
    icon: 'vehicalMonitoring',
    text: i18n.t('general.menuItems.vehiclesMonitoring').toString(),
    link: '/vehicles-monitoring',
  };

  const CONTACT: MenuItem = {
    icon: 'contacts',
    text: i18n.t('general.menuItems.contacts').toString(),
    link: '/contacts',
  };

  const LOCATION: MenuItem = {
    icon: 'location',
    text: i18n.t('general.menuItems.locations').toString(),
    link: '/locations',
  };

  const PARTNER: MenuItem = {
    icon: 'partners',
    text: i18n.t('general.menuItems.organizationPartners').toString(),
    link: '/partners',
  };

  const OPERATION: MenuItem = {
    icon: 'operations',
    text: i18n.t('general.menuItems.operations').toString(),
    link: '/operations',
  };

  const CONTRACT: MenuItem = {
    icon: 'contracts',
    text: i18n.t('general.menuItems.contracts').toString(),
    link: '/contracts',
  };

  const ORDER_TYPE: MenuItem = {
    icon: 'order',
    text: i18n.t('general.menuItems.orderTypes').toString(),
    link: '/order-types',
  };

  const ORDER: MenuItem = {
    icon: 'orders',
    text: i18n.t('general.menuItems.orders').toString(),
    link: '/orders',
  };

  const CARGO: MenuItem = {
    icon: 'cargo',
    text: i18n.t('general.menuItems.cargos').toString(),
    link: '/cargos',
  };

  const TRIP: MenuItem = {
    icon: 'trips',
    text: i18n.t('general.menuItems.trips').toString(),
    link: '/trips',
  };

  const REPORT: MenuItem = {
    icon: 'reports',
    text: i18n.t('general.menuItems.reports').toString(),
    link: '/reports',
  };

  const ROUTE_ZONE: MenuItem = {
    icon: 'routesZone',
    text: i18n.t('general.menuItems.routeZones').toString(),
    link: '/routes-zones',
  };

  const DASHBOARD: MenuItem = {
    icon: 'dashboard',
    text: i18n.t('general.menuItems.dashboards').toString(),
    link: '/dashboards',
  };

  const VEHICLE_SCHEDULE: MenuItem = {
    icon: 'vehicleSchedules',
    text: i18n.t('general.menuItems.vehicleSchedule').toString(),
    link: '/vehicles-schedule',
  };

  const ROUTE: MenuItem = {
    icon: 'routes',
    text: i18n.t('general.menuItems.routes').toString(),
    link: '/routes',
  };

  const EPC: MenuItem = {
    icon: 'EPC',
    text: i18n.t('general.menuItems.epc').toString(),
    link: '/epc',
  };
  const DRIVERS: MenuItem = {
    icon: 'drivers',
    text: i18n.t('general.menuItems.drivers').toString(),
    link: '/drivers',
  };
  let menuItems: MenuItem[] = [];

  if (user.role === UserRole.ROOT) {
    menuItems = [
      ORGANIZATION,
      USER,
      VEHICLE,
      VEHICLE_MONITORING,
      VEHICLE_DICTIONARY,
      CONTACT,
      LOCATION,
    ];
  } else {
    menuItems = [];

    if (user.rules.find(r => r.model === UserModel.USER && r.rule === UserRule.READ)) {
      menuItems.push(USER);
    }
    if (user.rules.find(r => r.model === UserModel.VEHICLE && r.rule === UserRule.READ)) {
      if ([OrganizationType.fleetOwner].includes(user.organizationType)) {
        menuItems.push(DRIVERS);
      }
    }
    if (user.rules.find(r => r.model === UserModel.ORGANIZATIONS && r.rule === UserRule.READ)) {
      menuItems.push(ORGANIZATION);
    }

    if (user.rules.find(r => r.model === UserModel.PARTNER && r.rule === UserRule.READ)) {
      menuItems.push(PARTNER);
    }

    if (user.rules.find(r => r.model === UserModel.VEHICLE && r.rule === UserRule.READ)) {
      menuItems.push(VEHICLE, VEHICLE_MONITORING);
      if ([OrganizationType.fleetOwner, OrganizationType.pl2].includes(user.organizationType)) {
        if (user.rules.find(r => r.model === UserModel.VEHICLE && r.rule === UserRule.READ)) {
          menuItems.push(VEHICLE_SCHEDULE);
        }
      }
    }
    if (user.rules.find(r => r.model === UserModel.ORDER_TYPE && r.rule === UserRule.READ)) {
      menuItems.push(ORDER_TYPE);
    }
    if (user.rules.find(r => r.model === UserModel.CARGO && r.rule === UserRule.READ)) {
      menuItems.push(CARGO);
    }
    if (user.rules.find(r => r.model === UserModel.CONTRACT && r.rule === UserRule.READ)) {
      menuItems.push(CONTRACT);
    }
    if (user.rules.find(r => r.model === UserModel.OPERATION && r.rule === UserRule.READ)) {
      menuItems.push(OPERATION);
    }
    if (user.rules.find(r => r.model === UserModel.CONTACT && r.rule === UserRule.READ)) {
      menuItems.push(CONTACT);
    }
    if (user.rules.find(r => r.model === UserModel.ROUTE_ZONE && r.rule === UserRule.READ)) {
      menuItems.push(ROUTE_ZONE);
    }
    if (user.rules.find(r => r.model === UserModel.ROUTE && r.rule === UserRule.READ)) {
      menuItems.push(ROUTE);
    }
    if (user.rules.find(r => r.model === UserModel.ORDER && r.rule === UserRule.READ)) {
      menuItems.push(ORDER);
    }
    if (user.rules.find(r => r.model === UserModel.TRIP && r.rule === UserRule.READ)) {
      menuItems.push(TRIP);
    }
    if (user.rules.find(r => r.model === UserModel.REPORT && r.rule === UserRule.READ)) {
      menuItems.push(REPORT);
    }
    if (user.rules.find(r => r.model === UserModel.DASHBOARD && r.rule === UserRule.READ)) {
      menuItems.push(DASHBOARD);
    }
    if (user.rules.find(r => r.model === UserModel.TRIP && r.rule === UserRule.READ)) {
      if ([OrganizationType.pl4, OrganizationType.pl3].includes(user.organizationType)) {
        menuItems.push(EPC);
      }
    }
  }
  return menuItems;
}

export interface MenuItem {
  icon: string
  text: string
  link: string
}
