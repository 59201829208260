import { HTTP, Pagination } from '@/core';
import { FindAllOptions, LocationItem, StoreLocationRequest } from './interface';

export class LocationsService {
  static async store(address: StoreLocationRequest): Promise<LocationItem> {
    const { data } = await HTTP.post('locations', address);

    return data;
  }

  static async findAll(params: FindAllOptions): Promise<Pagination<LocationItem>> {
    const { data } = await HTTP.get('locations', { params });

    return data;
  }

  static async findOne(id: string): Promise<LocationItem> {
    const { data } = await HTTP.get(`locations/${id}`);

    return data;
  }

  static async update(addressId: string, address: Partial<StoreLocationRequest>): Promise<LocationItem> {
    const { data } = await HTTP.patch(`locations/${addressId}`, address);

    return data;
  }

  static async delete(addressId: string): Promise<void> {
    await HTTP.delete(`locations/${addressId}`);
  }
}
